import { GeneriqueResponse } from './../../api/models/shared/generiqueResponse';
import { TiersResponse } from './../../api/models/response/tiers/tiersResponse';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { lastValueFrom, take, tap } from 'rxjs';
import { DemandeBranchementRequest } from 'src/app/api/models/request/abonnement/demandeBranchementRequest';
import { AbonnementService } from 'src/app/api/services/abonnement.service';
import { TiersService } from 'src/app/api/services/tiers.service';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import * as fromHeader from 'src/app/core/state/header';
import * as fromWizard from 'src/app/core/state/wizard';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { FrameComponent } from 'src/app/shared/components/frame/frame.component';
import { WizardComponent } from 'src/app/shared/components/wizard/wizard/wizard.component';
import { WaterpSwal } from 'src/app/shared/models/waterp-swal';
import { WizardStep } from 'src/app/shared/models/wizardStep';
import { ApiRequestBodyUtils } from 'src/app/shared/utils/apiRequestBodyUtils';
import { ApiResponseBodyUtils } from 'src/app/shared/utils/apiResponseBodyUtils';
import { formatDate } from 'src/app/shared/utils/dateUnixUtils';
import {
  getAbonnementDto_DB,
  getClientDto_DB,
  getPaiementDto_DB,
  getProprietaireDto_DB,
  getSegmentationDto_DB
} from 'src/app/shared/utils/wizardUtils';
import Swal from 'sweetalert2';
import { String } from 'typescript-string-operations';

@Component({
  selector: 'app-demande-branchement',
  templateUrl: './demande-branchement.component.html',
  styleUrls: ['./demande-branchement.component.scss'],
  imports: [CommonModule, FrameComponent, WizardComponent],
  standalone: true
})
export class DemandeBranchementComponent extends BaseComponent implements OnInit {
  wizardSteps: WizardStep[] = [];

  idTiersAbo!: string;
  idTiersDest!: string;
  tiersAbo!: TiersResponse;
  tiersDest!: TiersResponse;

  codeINSEE!: string;
  submitIsLoading: boolean = false;

  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService,
    private tierService: TiersService,
    private abonnementService: AbonnementService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    super(store, resources, messageService);

    const codeINSEELocal = this.activatedRoute.snapshot.paramMap.get('codeInsee');
    if (codeINSEELocal) this.codeINSEE = codeINSEELocal;

    this.idTiersAbo = this.activatedRoute.snapshot.paramMap.get('idTiersAbo') ?? this.idTiersAbo;

    const idTiersDest = this.activatedRoute.snapshot.paramMap.get('idTiersDest');
    if (idTiersDest) this.idTiersDest = idTiersDest;

    this.wizardSteps = [
      {
        step: 1,
        libelle: this.resource.wizard.localisation,
        srcIcon: this.resource.svgPath.location_g,
        srcIconShow: this.resource.svgPath.location_w,
        srcIconValid: this.resource.svgPath.location_b,
        srcIconTip: '',
        ignoreStep: false,
        tipIsPossible: false,
        show: true,
        valid: false
      },
      {
        step: 2,
        libelle: this.resource.wizard.informationsClient,
        srcIcon: this.resource.svgPath.client_g,
        srcIconShow: this.resource.svgPath.client_w,
        srcIconValid: this.resource.svgPath.client_b,
        srcIconTip: '',
        ignoreStep: true,
        tipIsPossible: false,
        show: false,
        valid: false
      },
      {
        step: 3,
        libelle: this.resource.wizard.ProprietaireGerant,
        srcIcon: this.resource.svgPath.vpnKey_g,
        srcIconShow: this.resource.svgPath.vpnKey_w,
        srcIconValid: this.resource.svgPath.vpnKey_b,
        srcIconTip: '',
        ignoreStep: false,
        tipIsPossible: false,
        show: false,
        valid: false
      },
      {
        step: 4,
        libelle: this.resource.wizard.referencesBancaire,
        srcIcon: this.resource.svgPath.payment_g,
        srcIconShow: this.resource.svgPath.payment_w,
        srcIconValid: this.resource.svgPath.payment_b,
        srcIconTip: this.resource.svgPath.payment_s,
        ignoreStep: false,
        tipIsPossible: true,
        show: false,
        valid: false
      },
      {
        step: 5,
        libelle: this.resource.wizard.informationsSegmentation,
        srcIcon: this.resource.svgPath.list_g,
        srcIconShow: this.resource.svgPath.list_w,
        srcIconValid: this.resource.svgPath.list_b,
        srcIconTip: '',
        ignoreStep: false,
        tipIsPossible: false,
        show: false,
        valid: false
      },
      {
        step: 6,
        libelle: this.resource.wizard.document,
        srcIcon: this.resource.svgPath.fileText_g,
        srcIconShow: this.resource.svgPath.fileText_w,
        srcIconValid: '',
        srcIconTip: '',
        ignoreStep: false,
        tipIsPossible: false,
        show: false,
        valid: false
      }
    ];
  }

  override async ngOnInit() {
    let title = 'Demande de branchement';
    this.store.dispatch(fromHeader.updateTitle({ title }));

    if (this.idTiersAbo) {
      this.tiersAbo = ApiResponseBodyUtils.ExtractResponseBody<TiersResponse>(
        await lastValueFrom(
          this.tierService.get(this.idTiersAbo).pipe(
            tap((response: GeneriqueResponse) => {
              if (response.Code != 100) {
                this.messageServiceUtils.warning(
                  response.Description ??
                    String.format(this.resource.toast.errorIdTiersGet, 'Abonné', this.idTiersAbo)
                );
              }
            })
          )
        )
      );
    }

    if (this.idTiersDest) {
      this.tiersDest = ApiResponseBodyUtils.ExtractResponseBody<TiersResponse>(
        await lastValueFrom(
          this.tierService.get(this.idTiersDest).pipe(
            tap((response: GeneriqueResponse) => {
              if (response.Code != 100) {
                this.messageServiceUtils.warning(
                  response.Description ??
                    String.format(
                      this.resource.toast.errorIdTiersGet,
                      'Destinataire',
                      this.idTiersDest
                    )
                );
              }
            })
          )
        )
      );
    }

    //#region reset si tout OK
    this.store.dispatch(fromWizard.resetLocalisation());
    this.store.dispatch(fromWizard.resetClient());
    this.store.dispatch(fromWizard.resetProprietaire());
    this.store.dispatch(fromWizard.resetReferenceBancaire());
    this.store.dispatch(fromWizard.resetInfoSegmentation());
    this.store.dispatch(fromWizard.resetDocument());
    //#endregion

    this.store.dispatch(
      fromWizard.updateClient({
        payload: {
          codeCivilite: this.tiersAbo?.Civilite?.Code ?? this.tiersAbo?.QualiteClient?.Code,
          nom:
            this.tiersAbo?.Prenom != undefined
              ? this.tiersAbo?.Nom + ' ' + this.tiersAbo?.Prenom
              : this.tiersAbo?.Nom,
          numeroRue: null,
          numeroDansLaRue: null,
          cpl: null,
          etage: null,
          telephone1: this.tiersAbo?.TelephoneTiers[0]?.FormatedNumeroTelephone,
          telephone2: this.tiersAbo?.TelephoneTiers[1]?.FormatedNumeroTelephone,
          email: this.tiersAbo?.AdresseMail,
          dateNaissance: this.tiersAbo?.DateNaissance
            ? formatDate(this.tiersAbo?.DateNaissance)
            : '',
          numeroSIRET: this.tiersAbo?.NumeroSiret,
          codeAPE: this.tiersAbo?.CodeAPE,
          civiliteClient: this.tiersDest?.Civilite?.Code ?? this.tiersDest?.QualiteClient?.Code,
          raisonSociale: this.tiersDest?.Nom,
          prenomClient: this.tiersDest?.Prenom,
          adresse: this.tiersDest?.AdresseTiers?.Adresse,
          codePostal: this.tiersDest?.AdresseTiers?.CodePostal,
          bureauDistributeur: this.tiersDest?.AdresseTiers?.BureauDistributeur,
          ville: this.tiersDest?.AdresseTiers?.Ville,
          etatVerificationAdresse: this.tiersDest?.AdresseTiers?.EtatVerificationAdresse,
          identifiantTiersAbo: this.idTiersAbo,
          identifiantTiersDest: this.idTiersDest,
          indicateurPliNonDistribue: null
        }
      })
    );
  }

  async submitEvent(submit: boolean) {
    if (submit) {
      this.submitIsLoading = true;
      let wizard = await lastValueFrom(this.store.select(fromWizard.selectWizard).pipe(take(1)));

      const abonnementBody = getAbonnementDto_DB(wizard);
      const clientBody = getClientDto_DB(wizard);
      const paiementBody = getPaiementDto_DB(wizard);
      const proprietaireBody = getProprietaireDto_DB(wizard);
      const segmentationBody = getSegmentationDto_DB(wizard);
      let body: DemandeBranchementRequest;

      // On ne propose l'envoi d'un mail qu'aux tiers ayant une adresse mail...
      if (this.tiersAbo?.AdresseMail) {
        Swal.fire(
          new WaterpSwal(this.resource, 'warning', {
            title: this.resource.GlobalTexts.Generic.SweetAlert_Confirmation,
            text: this.resource.GlobalTexts.Abonnement.Communication.Courrier_Send
          })
        )
          .then(result => {
            body = ApiRequestBodyUtils.GenerateDemandeBranchementRequestBody(
              wizard.document.branchementEauPotable,
              wizard.document.branchementEauUsee,
              result.isConfirmed ? true : false,
              abonnementBody,
              clientBody,
              paiementBody,
              proprietaireBody,
              segmentationBody,
              wizard.document.numeroDemandeTravO ? wizard.document.numeroDemandeTravO : ''
            );

            this.demandeBranchement(body);
          })
          .catch(result => {
            console.error('Error', result);
          });
      }
      // ...Sinon, on lui propose directement d'imprimer le document
      else {
        body = ApiRequestBodyUtils.GenerateDemandeBranchementRequestBody(
          wizard.document.branchementEauPotable,
          wizard.document.branchementEauUsee,
          false,
          abonnementBody,
          clientBody,
          paiementBody,
          proprietaireBody,
          segmentationBody,
          wizard.document.numeroDemandeTravO ? wizard.document.numeroDemandeTravO : ''
        );
        this.demandeBranchement(body);
      }
    }
  }

  demandeBranchement(body: any) {
    this.abonnementService.demandeBranchement(body).subscribe({
      next: (res: GeneriqueResponse) => {
        // Dans le cas d'un kit_courrier absent, il n'y a pas d'id courrier, cela revient donc à un SUCCESS TOTAL, donc on procède comme le cas code 100
        if (res.Code === 100 || res.Code === 701) {
          //#region reset si tout OK
          this.store.dispatch(fromWizard.resetLocalisation());
          this.store.dispatch(fromWizard.resetClient());
          this.store.dispatch(fromWizard.resetProprietaire());
          this.store.dispatch(fromWizard.resetReferenceBancaire());
          this.store.dispatch(fromWizard.resetInfoSegmentation());
          this.store.dispatch(fromWizard.resetDocument());
          //#endregion

          this.submitIsLoading = false;

          Swal.fire(
            new WaterpSwal(this.resource, 'info', {
              title: this.resource.GlobalTexts.Generic.SaveSuccess,
              text: 'Redirection vers le nouvel abonnement: ' + res.Result['NumeroAbonnement']
            })
          )
            .then(() => {
              this.router.navigate(['abonnement/synthese', res.Result['NumeroAbonnement']]);
            })
            .catch(result => {
              console.error('Error', result);
            });
        } else if (res.Code === 141 || res.Code === 204) {
          //#region reset si tout OK
          this.store.dispatch(fromWizard.resetLocalisation());
          this.store.dispatch(fromWizard.resetClient());
          this.store.dispatch(fromWizard.resetProprietaire());
          this.store.dispatch(fromWizard.resetReferenceBancaire());
          this.store.dispatch(fromWizard.resetInfoSegmentation());
          this.store.dispatch(fromWizard.resetDocument());
          //#endregion

          Swal.fire(
            new WaterpSwal(this.resource, 'info', {
              title: this.resource.GlobalTexts.Generic.SaveSuccess,
              text:
                'Demande de branchement effectuée. Courrier à imprimer manuellement. Redirection vers le nouvel abonnement: ' +
                res.Result['NumeroAbonnement']
            })
          )
            .then(() => {
              this.submitIsLoading = false;
              this.router.navigate(['abonnement/synthese', res.Result['NumeroAbonnement']]);
            })
            .catch(result => {
              this.submitIsLoading = false;
              console.error('Error', result);
            });
        } else {
          this.messageServiceUtils.warning(res.Description ?? this.resource.toast.errorDescription);
          this.submitIsLoading = false;
        }
      },
      error: () => {
        this.messageServiceUtils.error();
        this.submitIsLoading = false;
      }
    });
  }
}
