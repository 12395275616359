import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AbstractService } from 'src/app/api/services/abstract.service';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class AppInsightService extends AbstractService {
  private appInsights!: ApplicationInsights;
  private env!: string;
  private appInsightsKey!: string;

  constructor(config: AppConfig, private httpClient: HttpClient, private router: Router) {
    super(config);

    httpClient.get('./assets/env/env.json').subscribe((envResponse: any) => {
      this.env = envResponse['env'];
      this.appInsightsKey = config.getConfig('appInsightsKey');
      this.initAppInsights();
    });
  }

  public initAppInsights() {
    if (!this.appInsights) {
      let angularPlugin = new AngularPlugin();
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.appInsightsKey,
          enableCorsCorrelation: true,  // Active la corrélation CORS
          extensions: [angularPlugin],
          extensionConfig: {
            [angularPlugin.identifier]: { router: this.router, errorServices: [new ErrorHandler()] }
          },
          enableAjaxErrorStatusText: true,
          enableAjaxPerfTracking: true,
          enableUnhandledPromiseRejectionTracking: true,
          enablePerfMgr: true,
          enableAutoRouteTracking: true,
          enableRequestHeaderTracking: true,
          enableResponseHeaderTracking: true
        }
      });
      this.appInsights.loadAppInsights();
    }
  }

  disableAppInsights() {
    if (this.appInsights != null) {
      this.appInsights.getCookieMgr().setEnabled(false);
      this.appInsights.unload();
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number, properties?: { [key: string]: any }) {
    this.appInsights.trackException(
      { exception: exception, severityLevel: severityLevel },
      properties
    );
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }
}
